<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.number" placeholder="请输入编号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 140px; max-width: 160px">
        {{ text }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 140px; max-width: 160px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" v-show="record.is_available"/>
          <a-popconfirm
            title="确定要禁用么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-show="record.is_available"
          >
            <a>禁用</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-form
      ref="editModal"
      :visible="editvisible"
      :loading="editconfirmLoading"
      :model="editmdl"
      @cancel="handleEditCancel"
      @ok="handleEditOk"
      :key="key"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { water_meter_list, water_meter_create, water_meter_delete, water_meter_update } from '@/api/water_meter'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditForm from '../modules/EditForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      editvisible: false,
      editconfirmLoading: false,
      editmdl: {},
      key: 1,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: { is_available: true },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '公司',
          dataIndex: 'corporation'
        },
        {
          title: '编号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          width: 100,
          align: 'center',
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text]
        },
        {
          title: '初始读数',
          dataIndex: 'initial_value',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '价格',
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return water_meter_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    handleEditCancel () {
      this.editvisible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.editconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          water_meter_update(values, this.editmdl.id).then(res => {
            this.editvisible = false
            this.editconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('成功------')
          })
        } else {
          this.editconfirmLoading = false
        }
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.key++
      this.editmdl = record
      this.editvisible = true
    },
    handleDelet (record) {
      water_meter_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          values.price = Math.round(values.price * 100)
          water_meter_create(values).then(res => {
            if (!res.success) {
              this.$message.warn(res.msg)
            } else {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
            }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
