var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"修改","width":880,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"原企业"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'corporation',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ]
                }
              ]),expression:"[\n                'corporation',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ]\n                }\n              ]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"新企业"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'corporation_id',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ],
                },
              ]),expression:"[\n                'corporation_id',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入企业名称","default-active-first-option":false},on:{"search":_vm.corporationSearch,"change":_vm.corporationChange}},_vm._l((_vm.dataSource),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }