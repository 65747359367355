<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelsubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="corporation_id" v-show="false">
              <a-input v-decorator="['corporation_id']" />
            </a-form-item>
            <a-form-item label="企业名称" v-if="false">
              <a-auto-complete
                v-decorator="['corporation', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
            </a-form-item>
            <a-form-item label="企业名称">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'corporation_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入企业名称"
                :default-active-first-option="false"
                @search="this.corporationSearch"
                @change="handlecorporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="资产所有人">
              <a-select
                allow-clear
                v-decorator="[
                  'property_owner',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.electricity_meter_property_owner">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="初始读数">
              <a-input v-decorator="['initial_value', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="价格">
              <a-input v-decorator="['price', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_list } from '@/api/corporation'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.handlecorporationSearch, 800)
    return {
      dataSource: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      tip: ''
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handlecorporationSearch(undefined)
  },
  methods: {
    handelsubmit () {
      if (!this.tip) {
        this.$emit('ok')
      } else {
        this.$message.warning(this.tip)
      }
    },
    // 公司
    handlecorporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handlecorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    searchbody (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSearch (searchText) {
      this.$debounce(this.searchbody(searchText), 1000)
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    }
  }
}
</script>
